import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Ante Domjanovic', // e.g: 'Name | Developer'
  lang: 'EN', // e.g: en, es, fr, jp
  description: 'AD - FullStack Web Dev', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Ante Domjanovic',
  subtitle: "I'm Fullstack Web Developer.",
  cta: 'Find out more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne:
    'Well-organised person, problem solver, independent with high attention to detail providing web applications with a great user-experience.',
  paragraphTwo: 'Motivated and reliable with go-getter analytical mindset.',
  paragraphThree: 'Able to work a flexible schedule (agile), to plan and execute work in a timely manner.',
  resume: 'https://ante.domjanovic.dev', // if no resume, the button will not show up
};

// PROJECTS DATA
export const skillsData = [
  {
    id: nanoid(),
    img: 'skill2.svg',
    title: 'Technical skills',
    info: 'PHP & Symfony framework',
    info2: 'React & Redux ',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'skill1.svg',
    title: 'Soft skills',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'I’m interested in freelance opportunities to work with positive people and ambitious projects. If you find my skill set useful to you, or if you have other request or question, don’t hesitate to contact me.',
  btn: 'Send message!',
  email: 'ante@domjanovic.dev',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/adomjanovic',
    },
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/adomjanovic',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/adomjanovic/',
    },
  ],
};
